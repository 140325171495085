




// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import DriverOrders from '@/components/pages/orders/DriverOrders.vue'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'

@Component({
  components: {
    DriverOrders,
  },
})
export default class OrdersPageWrapper extends Mixins(SystemMixin) {
  // NOTE: редирект если роль не подходящая
  private created () {
    if (this.role !== 'driver') {
      this.$router.push({ name: 'orders.tabs', params: { tab: 'all' } })
    }
  }
}

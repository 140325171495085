




















































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import OrderCard from '@/components/cards/OrderCard.vue'
import CaptionCard from '@/components/cards/CaptionCard.vue'
import FiltersPanel from '@/components/FiltersPanel.vue'
import TableFooter from '@/components/TableFooter.vue'

// INTERFACES
import { CountResource, PaginationMeta, RequestsDriverCarIdGetParams, RequestShortResource } from '@/store/types'

// STORE
import DriverApplicationsModule from '@/store/modules/driver/applications'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import NotifyMixin from '@/mixins/NotifyMixin'

@Component({
  components: {
    OrderCard,
    CaptionCard,
    FiltersPanel,
    TableFooter,
  },
})
export default class DriverOrders extends Mixins(SystemMixin, NotifyMixin) {
  private isReady = false

  private get isEmpty (): boolean {
    return !this.tableData || !this.tableData.length
  }

  private get tableData (): RequestShortResource[] {
    return DriverApplicationsModule.applications.data
  }

  private get statuses (): CountResource[] {
    return DriverApplicationsModule.statusesDictionaries
  }

  private get paginator (): PaginationMeta {
    return DriverApplicationsModule.applications.meta
  }

  private get filters (): RequestsDriverCarIdGetParams {
    return DriverApplicationsModule.filters
  }

  private set filters (value: RequestsDriverCarIdGetParams) {
    DriverApplicationsModule.setTableFilters(value)
    DriverApplicationsModule.getTableData(+this.entity)
  }

  private get activeStatus (): string | undefined {
    return DriverApplicationsModule.filters.status
  }

  private set activeStatus (value: string | undefined) {
    DriverApplicationsModule.setActiveStatus(value)
    DriverApplicationsModule.getTableData(+this.entity)
  }

  private mounted () {
    DriverApplicationsModule.resetTableFilters()

    DriverApplicationsModule.getTableData(+this.entity)
      .finally(() => {
        this.isReady = true
      })
    DriverApplicationsModule.getTableStatuses(+this.entity)
  }

  private handleChangeTab () {
    this.isReady = false
    DriverApplicationsModule.getTableData(+this.entity)
      .finally(() => {
        this.isReady = true
      })
    DriverApplicationsModule.getTableStatuses(+this.entity)
  }

  private beforeDestroy () {
    DriverApplicationsModule.resetTableFilters()
  }
}
